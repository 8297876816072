import React from "react";
import Layout from "../../components/Layout";
// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";
import { graphql, PageProps } from "gatsby";
import Container from "react-bootstrap/Container";
import "./styles.scss";
import SEO from "../../components/Seo";
import Helmet from "react-helmet";
import { LocalisedPageContext } from "../../types/PageTypes";
import StructuredData from "../../components/StructuredData";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { getLocalizedPath } from "../../utils/gatsbyBuild";
import { withPrefix } from "gatsby";
import { ImageInterface, LocalizedContextInterface, PortableText, Seo, SanityColorList } from "../../types/SanityTypes";
import HeroBanner from "../../components/HeroBanner";
import { RichTextSerializers } from "../../utils/richTextSerializers";
import ContactUsForm from "../../components/ContactUsForm";

type ContactUsQueryProps = {
  sanityContactUs: {
    _id: string;
    banner: {
      heading: string;
      subHeading?: string;
      contentAlignment: string;
      _rawHeroImage: ImageInterface;
      _rawHeroVideo: any;
      primaryColor: SanityColorList;
      isVideoBanner: boolean;
    };
    body: PortableText;
    seo: Seo;
    slug: {
      current: string;
    };
  };
} & LocalizedContextInterface;

type ContactUsPageContext = LocalisedPageContext;

export const query = graphql`
  query ContactUsData($_id: String, $language: String) {
    sanityContactUs(_id: { eq: $_id }) {
      _id
      banner {
        heading
        subHeading
        primaryColor {
          value
          title
        }
        contentAlignment
        isVideoBanner
        _rawHeroImage(resolveReferences: { maxDepth: 10 })
        _rawHeroVideo(resolveReferences: { maxDepth: 10 })
      }
      body: _rawBody(resolveReferences: { maxDepth: 10 })
      seo {
        metaTitle
        metaDescription
      }
      slug {
        current
      }
    }
    ...LocalizedContext
  }
`;

const ContactUs = (props: PageProps<ContactUsQueryProps, ContactUsPageContext>) => {
  const language = props.pageContext.language;
  const contact = props.data.sanityContactUs;

  const { htmlLang, contactUsBrandId, contactUsEnv, contactUsFormType, contactUsLocale, siteUrl } =
    useSiteMetadata(language);

  const pageTitle = contact.banner.heading;
  const localizedPath = withPrefix(
    getLocalizedPath(props.data?.sanityContactUs?.slug?.current, props.data?.sanityContactUs?._id)
  );

  return (
    <Layout
      localizedContext={{ ...props.pageContext, ...props.data }}
      pageName={pageTitle}
      pageType={"contact"}
      analyticsTagMeta={{ promoId: "13" }}
    >
      <StructuredData
        type={"ContactPage"}
        data={contact.banner._rawHeroImage}
        language={htmlLang}
        slug={`/${contact.slug.current}/`}
      />
      <Helmet>
        <link rel="canonical" href={`${siteUrl}${localizedPath}`} />
      </Helmet>
      <SEO
        title={contact.seo.metaTitle}
        description={contact.seo.metaDescription}
        imageUrl={contact.banner._rawHeroImage.asset.url}
        imageAlt={contact.banner._rawHeroImage.alt}
      />
      <div className="page_contact-us">
        <HeroBanner
          sanityImage={contact.banner._rawHeroImage}
          title={contact.banner.heading}
          alignment={contact.banner.contentAlignment}
          isVideoBanner={contact.banner.isVideoBanner}
          video={contact.banner._rawHeroVideo}
        />
        <Container fluid className="theme_blue" data-testid="contact-us">
          <div className="rich-text">
            <BlockContent blocks={contact.body} serializers={RichTextSerializers()} />
          </div>
          <div className="page_get-in-touch">
            <ContactUsForm
              env={contactUsEnv}
              locale={contactUsLocale}
              brandId={contactUsBrandId}
              formType={contactUsFormType}
            />
          </div>
        </Container>
      </div>
    </Layout>
  );
};

export default ContactUs;

import { EventType, pushEvent } from "./index";

/**
 * Contact Us
 */
export const event33 = (contactUsType: string) => {
  pushEvent({
    eventInfo: {
      type: EventType.trackEvent,
      eventAction: EventType.contactus,
      eventLabel: contactUsType,
      eventValue: 1
    },
    category: {
      primaryCategory: EventType.other
    }
  });
};

import React, { FunctionComponent } from "react";
import { Helmet } from "react-helmet";
import "./styles.scss";
import { event33 } from "../../analytics/event33";

type ContactUsFormProps = {
  env: string;
  locale: string;
  brandId: string;
  formType: string;
};

type FormType = {
  scriptSrc: string;
  className: string;
};

const ContactUsForm: FunctionComponent<ContactUsFormProps> = ({ env, locale, brandId, formType }) => {
  const formTypeMap: Record<string, FormType> = {
    ac: {
      scriptSrc: `https://d1a1ax4tcp3m3j.cloudfront.net/${env}/${locale}/${brandId}/${locale}_${brandId}.js`,
      className: "contact-us-form-ac"
    },
    twh: {
      scriptSrc: `https://d1a1ax4tcp3m3j.cloudfront.net/${env}/TWH/${locale}/${brandId}/${locale}_${brandId}.js`,
      className: "contact-us-twh-generic"
    }
  };

  const scriptId = "contactUsScript";

  return (
    <section className="contact-us-form" data-testid="contact-us-form">
      <Helmet
        onChangeClientState={(_, addedTags) => {
          if (addedTags.scriptTags) {
            addedTags.scriptTags.forEach(s => {
              if (s.id === scriptId) {
                const contactUsScript = document.querySelector(`#${scriptId}`) as HTMLScriptElement;
                contactUsScript.onload = () => {
                  const contactFormElement = document.querySelector(`[name='contactUs']`) as HTMLFormElement;
                  contactFormElement.addEventListener("submit", () => event33("Email Us"));
                };
              }
            });
          }
        }}
      >
        <script defer id={scriptId} type="text/javascript" src={formTypeMap[formType].scriptSrc} />
      </Helmet>
      <section className={`${formTypeMap[formType].className} contact-us-section`}>
        <form>Contact us Form</form>
      </section>
    </section>
  );
};

export default ContactUsForm;
